import {Route, Routes, Navigate} from "react-router";
import {OAuthCallback} from "./pages/OAuthCallback";
import {CreateSurvey} from "./pages/CreateSurvey";
import {Login} from "./pages/Login";
import {TakeSurvey} from "./pages/TakeSurvey";
import {ManageSurvey} from "./pages/ManageSurvey";
import {RequireAuth} from "./auth/RequireAuth";
import {ErrorModal} from "./generalComponents/ErrorModal";
import {RequireAdmin} from "./auth/RequireAdmin";
import {PageNotFound} from "./pages/PageNotFound";
import { SurveyDownloadPage } from "./pages/SurveyDownloadPage";

function RoutePaths() {
    return (
        <>
            <Routes>
                <Route path="/oauth-callback" element={<OAuthCallback/>}/>
                <Route path=":tokenInfo" element={<OAuthCallback/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route element={<RequireAuth/>}>
                    <Route element={<RequireAdmin/>}>
                        <Route path="/create-survey" element={<CreateSurvey/>}/>
                        <Route path="/create-survey/:id" element={<CreateSurvey/>}/>
                        <Route path="/manage-surveys" element={<ManageSurvey/>}/>
                    </Route>
                    <Route path="/" element={<Navigate to={"/manage-surveys"}/>}/>
                    <Route path="/take-survey" element={<TakeSurvey/>}/>
                    <Route path="/take-survey/:id" element={<TakeSurvey/>}/>
                    <Route path="/404" element={<PageNotFound/>}/>
                    <Route path="/export/:id" element={<SurveyDownloadPage/>}/>
                    
                </Route>
            </Routes>
            <ErrorModal/>
        </>
    );
}

export default RoutePaths;
