import "./TakeLongCSS.css";
import { getGlobalState, setGlobalState } from "../../State/TakeSurveyGlobalState";
import { BaseSyntheticEvent } from "react";

interface TakeLongProps {
    index: number
}

export function TakeLongAnswer({index}: TakeLongProps) {
    const updateAnswers = (event:BaseSyntheticEvent) =>{
        const answers = getGlobalState("answers");
        answers[index] = event.target.value;
        setGlobalState("answers", answers);
    }

    return (
        <div className={'answer-section'}>
            <textarea className={'long-answer-box'}
                      data-testid={'long-answer-box'}
                      placeholder={"Type Here..."}
                      onBlur={updateAnswers}
            />
        </div>
    )
}