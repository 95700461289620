import "./TakeRadioQuestionCSS.css";
import { ITakeAnswerBoxSetup } from "../takeAnswerBox/ITakeAnswerBoxSetup";
import { TakeAnswerBox } from "../takeAnswerBox/TakeAnswerBox";

interface TakeRadioQuestionProps {
  answers: string[],
  index: number
}

export function TakeRadioQuestion({ answers, index }: TakeRadioQuestionProps) {

  const radioAnswers: ITakeAnswerBoxSetup = {
    testId: "take-radio-question",
    answerType: "radio",
    answerId: "radioAnswer"
  };

  return TakeAnswerBox(answers, index, radioAnswers);
}
