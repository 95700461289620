import MDEditor from "@uiw/react-md-editor";
import "./TakeBlockTextCSS.css";

interface TakeBlockTextProps {
    hold: string
}

export function TakeBlockText(props: TakeBlockTextProps) {
    return (
        <>
            <div className={"info-section"}>
                <MDEditor.Markdown
                    className={"take-markdown"}
                    source={props.hold}/>
            </div>
        </>
    );
}