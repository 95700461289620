import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import RoutePaths from "./RoutePaths";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
    <React.StrictMode>
      <BrowserRouter>
          <RoutePaths/>
      </BrowserRouter>
    </React.StrictMode>
);


serviceWorker.unregister();
