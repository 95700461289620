import React, {useEffect, useState} from "react";
import {SurveyHeader} from "../generalComponents/SurveyHeader";
import {SurveyFooter} from "../generalComponents/SurveyFooter";
import {getAllSurveys} from "../serviceFunctions/SurveyService";
import "react-accessible-accordion/dist/fancy-example.css";
import "../Stylesheets/ManageSurvey.css";
import {AlertModal} from "../generalComponents/AlertModal";
import {useNavigate} from "react-router-dom";
import {SurveyInterface} from "../interfaces/SurveyInterface";
import {FaPlus} from "react-icons/fa";
import {SurveyCard} from "../generalComponents/SurveyCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFolderBlank} from "@fortawesome/free-regular-svg-icons/faFolderBlank";
import {faCircle} from "@fortawesome/free-regular-svg-icons";
import {faList} from "@fortawesome/free-solid-svg-icons";
import {isSurveyInactive} from "../utils/IsSurveyInactive";

export function ManageSurvey() {
    const [surveyList, setSurveyList] = useState<SurveyInterface[]>([]);
    const [filterState, setFilterState] = useState("");
    const [showLinkModal, setShowLinkModal] = useState(false);
    const navigate = useNavigate();
    const filterOptions = ["unpublished", "inactive", "active"];

    useEffect(() => {
        updateSurveyList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterState]);

    function updateSurveyList() {
        let response = getAllSurveys();
        let newSurveyList: SurveyInterface[] = [];

        Promise.resolve(response).then((value) => {
            if (value !== undefined) {
                value.data?.forEach((survey) => {
                    newSurveyList.push(survey);
                });
                filterSurveyList(newSurveyList);
            }
        });
    }

    function filterSurveyList(newSurveyList: SurveyInterface[]) {
        let filteredSurveyList = []
        if (filterState === "unpublished") {
            filteredSurveyList = newSurveyList.filter(survey => !survey.published);
            setSurveyList(filteredSurveyList);
        } else if (filterState === "inactive") {
            filteredSurveyList = newSurveyList.filter(survey => isSurveyInactive(survey));
            setSurveyList(filteredSurveyList);
        } else if (filterState === "active") {
            filteredSurveyList = newSurveyList.filter(survey => !isSurveyInactive(survey));
            setSurveyList(filteredSurveyList);
        } else {
            setSurveyList(newSurveyList);
        }
    }

    function handleCloseLinkModal() {
        setShowLinkModal(false);
    }

    function generateSurveyList() {
        return <>
            <div className={"surveys-container"}>
                {surveyList.map((survey, _index) => {
                    return (
                        <div>
                            <SurveyCard key={survey.id} initialSurvey={survey} updateSurveyList={updateSurveyList}/>
                        </div>
                    );
                })}
            </div>
        </>;
    }

    const createSurveyURL = "/create-survey";

    const changeRoute = () => {
        navigate(createSurveyURL);
    };

    function getCreateSurveyButton() {
        return <span className={"create-survey-container"}>
              <button className={"survey-button create-survey-button"}
                      onClick={changeRoute}>
                {<FaPlus/>} New Survey
              </button>
            </span>;
    }

    function getFilterBar() {
        return <>
            <div className="filter-bar"
                 data-testid={"filter-bar"}
                 role="group"
                 aria-label="Filter bar for Surveys">
                <input type="radio"
                       className="btn-check"
                       name="btnradio"
                       id="all-surveys-filter"
                       checked={!filterOptions.includes(filterState)}
                       onClick={() => setFilterState("")}/>
                <label className="btn btn-light filter-bar-button"
                       htmlFor="all-surveys-filter"
                       aria-label="all surveys">
                    <FontAwesomeIcon data-testid={"all-surveys-icon"}
                                     icon={faList}
                    />
                    All Surveys
                </label>

                <input type="radio"
                       className="btn-check"
                       name="btnradio"
                       id="unpublished-filter"
                       onClick={() => setFilterState("unpublished")}
                       checked={filterState === "unpublished"}/>
                <label className="btn btn-light filter-bar-button"
                       htmlFor="unpublished-filter"
                       aria-label="unpublished">
                    <FontAwesomeIcon data-testid={"unpublished-icon"}
                                     icon={faFolderBlank}
                    />
                    Unpublished
                </label>

                <input type="radio"
                       className="btn-check"
                       name="btnradio"
                       id="inactive-filter"
                       checked={filterState === "inactive"}
                       onClick={() => setFilterState("inactive")}/>
                <label className="btn btn-light filter-bar-button"
                       htmlFor="inactive-filter"
                       aria-label="inactive">
                    <FontAwesomeIcon data-testid={"inactive-icon"}
                                     icon={faCircle}/>
                    Inactive
                </label>

                <input type="radio"
                       className="btn-check"
                       name="btnradio"
                       id="active-filter"
                       checked={filterState === "active"}
                       onClick={() => setFilterState("active")}/>
                <label className="btn btn-light filter-bar-button"
                       htmlFor="active-filter"
                       aria-label="active">
                    <FontAwesomeIcon className={"survey-active-circle"}
                                     data-testid={"active-icon"}
                                     icon={faCircle}
                    />
                    Active
                </label>
            </div>

            <select className={"filter-dropdown"}
                    data-testid={"filter-dropdown"}
                    value={!filterOptions.includes(filterState) ? "All Surveys" : filterState}
                    onChange={(e) => setFilterState(e.target.value)}>
                <option value={""}>All Surveys</option>
                <option value={"unpublished"}>Unpublished</option>
                <option value={"inactive"}>Inactive</option>
                <option value={"active"}>Active</option>
            </select>
        </>;
    }

    return (
        <>
            <AlertModal show={showLinkModal} onHide={handleCloseLinkModal} message={"Link Copied to Clipboard"}/>
            <SurveyHeader/>
            <main className={"main-container"}>
                {getCreateSurveyButton()}
                {getFilterBar()}
                {generateSurveyList()}
            </main>
            <SurveyFooter/>
        </>
    );
}
