import "./QuestionButtonsCSS.css";

interface ButtonParams{
    addAnswer: ()=>void,
    removeAnswer: ()=>void,
    disableRemove: boolean
}

export function QuestionButtons(props: ButtonParams) {

    return(
        <div className={"button-container-row"}>
            <button data-testid={'add-answer-button'}
                    type="button"
                    className={"modify-answers"}
                    onClick={props.addAnswer}>+
            </button>
        </div>
    )
}