import {AlertModal} from "../generalComponents/AlertModal";
import {useState} from "react";

export function PageNotFound() {
    const [showErrorModal, setShowErrorModal] = useState(true);

    const handleCloseModal = () => {
        setShowErrorModal(false);
    };

    const errorMessage = "404 - This page does not exist. \n" +
        "Please close the window."

    return (
        <>
            <AlertModal show={showErrorModal} onClick={handleCloseModal}
                        data-testid="modal404" message={errorMessage}/>
        </>
    )
}