import { useCallback, useEffect, useState } from "react";
import "../../App.css";
import "./MultiAnswerCSS.css";
import { QuestionButtons } from "../QuestionButtons/QuestionButtons";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import update from "immutability-helper";
import { MultiAnswerOption } from "./MultiAnswerOption";

interface MultiAnswerProps {
  questionType: string,
  answers?: string[],
  updateAnswerArray?: (newAnswerArray: string[]) => void
}

export interface Item {
  id: number;
  text: string;
}

export function MultiAnswerBox(props: MultiAnswerProps) {
  const verifyAnswers = () => {
    if (!props.answers || props.answers.length === 0) {
      return ["", ""];
    } else if (props.answers.length === 1) {
      return [...props.answers, ""];
    } else {
      return props.answers;
    }
  };

  const [answers, setAnswers] = useState(verifyAnswers);

  const moveOption = useCallback((dragIndex: number, hoverIndex: number) => {
    setAnswers((prevCards: string[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as string]
        ]
      })
    );
  }, []);

  function updateAnswer(newAnswer: string, index: number) {
    answers[index] = newAnswer;
    setAnswers([...answers]);
  }

  const renderOption = useCallback(
    (answer: string, index: number) => {
      return (
        <MultiAnswerOption
          key={answer + index}
          index={index}
          id={index + 1}
          text={answer}
          moveOption={moveOption}
          updateAnswer={updateAnswer}
          removeAnswer={removeAnswer}
          disabledRemove={answers.length < 3}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [moveOption, [...answers], answers.length]
  );

  function addAnswer() {
    setAnswers((currentAnswers) => {
      return [...currentAnswers, ""];
    });
  }

  function removeAnswer(index: number) {
    if (answers.length > 2) {
      setAnswers((currentAnswers) => {
        currentAnswers.splice(index, 1);
        return [...currentAnswers];
      });
    }
  }

  useEffect(() => {
    if (props.updateAnswerArray) {
      props.updateAnswerArray(answers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [[...answers], answers]);

  return (
    <>
      <div className={"answer-section"} data-testid={props.questionType}>
        <DndProvider backend={HTML5Backend}>
          <div className={"container"}>
            {answers.map((answer, i) => (
              renderOption(answer, i)
            ))}
          </div>
        </DndProvider>

        <QuestionButtons addAnswer={addAnswer}
                         removeAnswer={()=>{return;}}
                         disableRemove={answers.length <= 2} />
      </div>
    </>
  );
}
