import {useGlobalState} from "../State/GlobalState";
import {AlertModal} from "./AlertModal";

export function ErrorModal() {

    const [errorModalVisible, setErrorModalVisible] = useGlobalState("errorModalVisible");

    const handleErrorModalHide = () => {
        setErrorModalVisible(false);
    }

    if (errorModalVisible) {
        return (
            <AlertModal
                show
                onHide={handleErrorModalHide}
                message={"Error, could not complete action."}/>
        )
    }
    return <></>
}