import logo from "../images/FordLogo.png";
import React from "react";
import "../Stylesheets/SurveyHeader.css";
import {FaHome} from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import {isAdmin} from "../auth/AdminParser";

export function SurveyHeader() {
    const manageSurveyURL = "/manage-surveys";

    let navigate = useNavigate();
    const changeRoute = () => {
        navigate(manageSurveyURL);
    };

    return (
        <header className={"header-section"}>
            <button data-testid={"home-button"}
                    onClick={changeRoute}
                    className={'home-button'}
                    title={"Manage Surveys"}
                    hidden={!isAdmin() || window.location.pathname === manageSurveyURL}>
                {<FaHome/>}
            </button>
            <h1 hidden>Ford Motor Company</h1>
            <img data-testid="survey-logo"
                 id={"survey-logo"}
                 className={"survey-logo"}
                 src={logo}
                 alt={"stylized ford name inside blue oval with white border"}
            />
            <div data-testid="header-name"
                 id={"header-name"}
                 className={'header-name'}>
                <h1 className="header-name-text">
                    Dealer Survey
                </h1>
            </div>
        </header>
    );
}
