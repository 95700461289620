import "../../App.css";
import "./MultiQuestionCSS.css";
import { MultiAnswerBox } from "../MultiAnswerBox/MultiAnswerBox";

interface MultiQuestionProps {
    answers?: string[],
    updateAnswerArray?: (newAnswerArray: string[])=>void
}
export function MultiQuestionBox(props:MultiQuestionProps) {
    return (<MultiAnswerBox questionType="multi-select" answers={props.answers} updateAnswerArray={props.updateAnswerArray}/>);
}
