import "../../App.css";
import "./LongAnswer.css";

export function LongAnswer() {
    return (
        <>
            <div className={"answer-section-textarea"}
                 data-testid={"long-answer"}>
                <textarea className={"long-answer-box"}
                        disabled={true}>
                </textarea>
            </div>
        </>
    );
}
