import { ITakeAnswerBoxSetup } from "../takeAnswerBox/ITakeAnswerBoxSetup";
import { TakeAnswerBox } from "../takeAnswerBox/TakeAnswerBox";

interface TakeRadioQuestionProps {
  answers: string[],
  index: number
}

export function TakeMultiSelect({ answers, index }: TakeRadioQuestionProps) {

  const radioAnswers: ITakeAnswerBoxSetup = {
    testId: "take-multi-question",
    answerType: "checkbox",
    answerId: "multiAnswer"
  };

  return TakeAnswerBox(answers, index, radioAnswers);
}
