import { ITakeAnswerBoxSetup } from "./ITakeAnswerBoxSetup";
import { getGlobalState, setGlobalState } from "../../State/TakeSurveyGlobalState";
import "./TakeAnswerBox.css";

export function TakeAnswerBox(answersList: string[],
                              index: number,
                              setup: ITakeAnswerBoxSetup) {

  function createId(baseID: string, localIndex: number) {
    return baseID + localIndex.toString();
  }

  function createSubId(baseID: string, localIndex: number, subIndex: number) {
    return baseID + localIndex.toString() + "-" + subIndex.toString();
  }

  let checked: boolean[] = [];
  answersList.forEach(() => {
      checked.push(false);
  })

  const updateAnswerHandler = (loc: number) => {
    if (setup.answerType === "radio") {
      const answers = getGlobalState("answers");
      answers[index] = answersList[loc];
      setGlobalState("answers", answers);
    } else {
      checked[loc] = !checked[loc];
      let value: string = "";

      for (let i = 0; i < answersList.length; ++i){
        if (checked[i]) {
          value += answersList[i] + ", ";
        }
      }

      const answers = getGlobalState("answers");
      answers[index] = value.slice(0, -2);
      setGlobalState("answers", answers);
    }
  }

  return (
    <div data-testid={setup.testId}
         className={"answer-section"}>
      {answersList.map((answer: string, loc: number) => {
        return (
          <div className={"answer-choices"}
               key={answer + loc}>
            <input className="form-check-input"
                   type={setup.answerType}
                   name={createId(setup.answerId, index)}
                   data-testid={createSubId(setup.answerId, index, loc)}
                   id={createSubId(setup.answerId, index, loc)}
                   alt={answer}
                   onClick={() => {
                       updateAnswerHandler(loc)
                   }}
            />
            <label className={"form-check-label"}
                   htmlFor={createSubId(setup.answerId, index, loc)}>
              {answer}
            </label>
          </div>
        );
      })}
    </div>
  );
}
