import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Stylesheets/AlertModal.css";

export function AlertModal(props: any) {
    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className={"header"}
                data-testid={"alert-modal"}>
                <p className={"message"}>
                    {props.message}
                </p>
            </Modal.Header>
            <Modal.Footer className={"footer"}>
                <button className={"modal-btn"} onClick={props.onHide} data-testid={"close-modal-button"} >OK</button>
            </Modal.Footer>
        </Modal>
    );
}
