import "../Stylesheets/SurveyFooter.css";
import React from "react";

export function SurveyFooter() {
  return <footer>
    <p>
      Copyright {new Date().getFullYear().toString()}, Ford Motor Company
    </p>
  </footer>;
}
