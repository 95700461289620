import { isAuthenticated, parseOAuthCallbackUrl } from "../auth/OAuthParser";
import {parseAndStoreRole} from "../auth/AdminParser";

export function OAuthCallback() {
  if (!isAuthenticated()) {
    if (window.location.href.search("#") !== -1) {
      parseOAuthCallbackUrl(window.location.href);
      parseAndStoreRole().then(() => {
        let url = sessionStorage.getItem("urlPathname");
        if (url !== null && url !== '') {
          sessionStorage.setItem("urlPathname", '')
          window.location.assign(url);
        }
      })
    }
  }

  return null;
}
