import {getRole} from "../serviceFunctions/SurveyService";

export function isAdmin() {
    return sessionStorage.getItem("role") === "ROLE_ADMIN";
}

export async function parseAndStoreRole() {
    sessionStorage.setItem("role", await getRole())
    // getRole().then((role) => {
    //     sessionStorage.setItem("role", role)
    // })
}