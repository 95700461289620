import React, {useEffect, useState} from "react";
import {ShortAnswer} from "../ShortAnswer/ShortAnswer";
import "../../App.css";
import "../../Stylesheets/CreateSurvey.css";
import {QuestionInterface} from "../../interfaces/QuestionInterface";
import {faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {RadioQuestionBox} from "../RadioQuestion/RadioQuestion";
import {MultiQuestionBox} from "../MultiQuestion/MultiQuestion";
import {RankQuestionBox} from "../RankQuestion/RankQuestion";
import {TrueFalse} from "../TrueFalse/TrueFalse";
import {BlockText} from "../BlockText/BlockText";
import {LongAnswer} from "../LongAnswer/LongAnswer";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-switch-button/css/bootstrap-switch-button.css";
import {Calendar} from "../../generalComponents/CalendarComponent/Calendar";

export interface QuestionProps {
    index: number,
    questionInterface: QuestionInterface,
    removeQuestion: (index: number) => void,
    updateQuestion: (question: QuestionInterface, index: number) => void,
    missingInfo: boolean
}

export function Question({index, questionInterface, removeQuestion, updateQuestion, missingInfo}: QuestionProps) {
    const [questionType, setQuestionType] = useState(questionInterface.type);
    const [questionText, setQuestionText] = useState(questionInterface.questionText);
    const [answers, setAnswers] = useState(questionInterface.answers);
    const [required, setRequired] = useState(questionInterface.required);

    const updateAnswerArray = (newAnswers: string[]) => {
        setAnswers(newAnswers);
    };

    useEffect(() => {
        const hold: QuestionInterface = {
            questionText: questionText,
            type: questionType,
            answers: answers,
            required: required
        };
        updateQuestion(hold, index);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionType, index, answers, questionText, required]);

    function getQuestionTextLabel() {
        let questionTextLabel = "Question Text";
        if (missingInfo && questionText === "") {
            questionTextLabel = "Question Text is missing";
        }

        return <label htmlFor={"question-" + JSON.stringify(index) + "-text"}
                      className={"required-asterisk"}>
            {questionTextLabel}
        </label>;
    }

    function getSelectTypeOption() {
        if(questionType === "blank" && missingInfo) {
            return "Select Type (Required)*"
        }

        return "Select Type*";
    }

    return (
        <>
            <span className={"bold-text"}>
                {"Question " + JSON.stringify(index + 1)}
            </span>
            <div className={"question"}
                 data-testid={"question-" + JSON.stringify(index)}>
                <FontAwesomeIcon className={"trash-icon"}
                                 icon={faCircleXmark}
                                 onClick={() => {
                                     removeQuestion(index);
                                 }}/>
                <div className={"question-header"}>
                    <div className={"question-options"}>
                        <div>
                            <select className={missingInfo && questionType === "blank"
                                ? "question-type-dropdown missing-info"
                                : "question-type-dropdown"}
                                    data-testid={"question-dropdown-" + index.toString()}
                                    value={questionType}
                                    onChange={(e) => {
                                        setQuestionType(e.target.value);
                                    }}
                                    aria-label={"question type"}
                                    id={"question-" + index.toString() + "-type"}>
                                <option value={"blank"} hidden={true}>{getSelectTypeOption()}</option>
                                <option value={"radio"}>Radio</option>
                                <option value={"multi-select"}>Multiple Choice</option>
                                <option value={"short-answer"}>Short Answer</option>
                                <option value={"long-answer"}>Long Answer</option>
                                <option value={"rank"}>Ranking</option>
                                <option value={"true-false"}>True/False</option>
                                <option value={"calendar"}>Calendar</option>
                                <option value={"block-text"}>Block Text</option>
                            </select>
                        </div>
                        <div className={"required-field"}>
                            <button
                                type={"button"}
                                className={"required-toggle-button"}
                                data-testid="checkbox"
                                onClick={(_) => {
                                    setRequired(!required);
                                }}
                                value="1"
                            >
                                {required && <>Required</>} {!required && <>Not Required</>}
                            </button>
                        </div>
                    </div>
                    {questionInterface.type !== "block-text" &&
                        <>
                            <div>
                                {getQuestionTextLabel()}
                            </div>
                            <input className={missingInfo && questionText === ""
                                ? "question-input missing-input"
                                : "question-input"}
                                   data-testid={"question-input-" + JSON.stringify(index)}
                                   id={"question-" + JSON.stringify(index) + "-text"}
                                   defaultValue={questionText}
                                   onBlur={(e) => {
                                       setQuestionText(e.target.value);
                                   }}>
                            </input>
                        </>}
                </div>
                {(() => {
                    if (questionInterface.type === "radio") {
                        return (<RadioQuestionBox answers={answers} updateAnswerArray={updateAnswerArray}/>);
                    } else if (questionInterface.type === "multi-select") {
                        return (<MultiQuestionBox answers={answers} updateAnswerArray={updateAnswerArray}/>);
                    } else if (questionInterface.type === "short-answer") {
                        return (<ShortAnswer/>);
                    } else if (questionInterface.type === "long-answer") {
                        return (<LongAnswer/>);
                    } else if (questionInterface.type === "rank") {
                        return (<RankQuestionBox answers={answers} updateAnswerArray={updateAnswerArray}/>);
                    } else if (questionInterface.type === "true-false") {
                        return (<TrueFalse/>);
                    } else if (questionInterface.type === "calendar") {
                        return (<Calendar index={index}/>);
                    } else if (questionInterface.type === "block-text") {
                        return (
                            <BlockText index={index} question={questionInterface} updateQuestion={updateQuestion}/>);
                    } else {
                        return (<div></div>);
                    }
                })()}
            </div>
        </>
    );
}
