import "./TakeShortCSS.css";
import { BaseSyntheticEvent } from "react";
import { getGlobalState, setGlobalState } from "../../State/TakeSurveyGlobalState";

interface TakeShortProps {
    index: number
}

export function TakeShortAnswer({index}: TakeShortProps) {
    const updateAnswers = (event:BaseSyntheticEvent) =>{
        const answers = getGlobalState("answers");
        answers[index] = event.target.value;
        setGlobalState("answers", answers);
    }

    return (
        <div className={'answer-section'}>
            <textarea className={'short-answer-box'}
                   data-testid={'short-answer-box'}
                   placeholder={"Type Here..."}
                      onBlur={updateAnswers}
            />
        </div>
    )
}