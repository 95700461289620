import { TakeRadioQuestion } from "../takeRadioQuestion/TakeRadioQuestion";
import "./takeQuestionCSS.css";
import { TakeShortAnswer } from "../takeShortAnswer/TakeShort";
import { TakeMultiSelect } from "../takeMultiSelect/TakeMultiSelect";
import { TakeLongAnswer } from "../takeLongAnswer/TakeLong";
import { Calendar } from "../../generalComponents/CalendarComponent/Calendar";
import { TakeBlockText } from "../takeBlockText/TakeBlockText";
import { TakeRankQuestion } from "../takeRankQuestion/TakeRank";
import { QuestionInterface } from "../../interfaces/QuestionInterface";

export interface TakeQuestionProps{
  index: number,
  q: QuestionInterface
}

export function TakeQuestion({index, q}: TakeQuestionProps){
  return (
    <>
      <div className={'question'}
           data-testid ={'question-' + JSON.stringify(index)}>
        {q.type !== "block-text" && <div className={'question-header'}>
            <label className={'question-title'}
                   data-testid={'question-title'}>
              {q.questionText}
              {q.required && <label id={'asterisk'}>*</label>}
            </label>
      </div>}
        {(()=>{
          if (q.type === "block-text"){
          return (<TakeBlockText hold={q.questionText} />)
        }})()}
        {q.type !== "block-text" && <div className={'answer-section'}>
        {(() => {
          if (q.type === 'radio') {
            return (<TakeRadioQuestion answers={q.answers} index={index}/>)
          } else if (q.type === 'short-answer') {
            return (<TakeShortAnswer index={index}/>)
          } else if (q.type === 'multi-select') {
            return (<TakeMultiSelect answers={q.answers} index={index}/>)
          } else if (q.type === 'long-answer') {
            return (<TakeLongAnswer index={index}/>)
          } else if (q.type === "true-false"){
            return (<TakeRadioQuestion answers={["True", "False"]} index={index}/>)
          }else if (q.type === "calendar"){
            return (<Calendar index={index} />)
          } else if (q.type === "rank") {
            return (<TakeRankQuestion answers={q.answers} index={index}/>)
          } else {
            return (<div>{q.type}</div>);
          }
        })()}
        </div>}
      </div>
    </>
  );
}
