import { isAuthenticated } from "./OAuthParser";
import { Navigate, Outlet, useLocation } from "react-router-dom";

export function RequireAuth() {
  const location = useLocation();

  if(!isAuthenticated()){
    sessionStorage.setItem('urlPathname',  location.pathname)
    return <Navigate to={'/login'}/>
  }
  return <Outlet/>;
}
