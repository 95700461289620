import "../../App.css";
import "./RadioQuestionCSS.css";
import { MultiAnswerBox } from "../MultiAnswerBox/MultiAnswerBox";

interface RadioQuestionProps {
    answers?: string[],
    updateAnswerArray?: (newAnswerArray: string[])=>void
}

export function RadioQuestionBox(props: RadioQuestionProps) {
    return (<MultiAnswerBox questionType="radio" answers={props.answers} updateAnswerArray={props.updateAnswerArray}/>);
}
