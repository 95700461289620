import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { AnswerContainer } from "./AnswerContainer";


interface TakeRankQuestionProps{
    answers: string[],
    index: number
}

export function TakeRankQuestion({answers, index}: TakeRankQuestionProps) {
    return (
        <div data-testid={"take-rank-question"}
             className={"take-rank-question"}>
            <DndProvider backend={HTML5Backend}>
                <AnswerContainer answers={answers} index={index}/>
            </DndProvider>
        </div>
    )
}