import "../../App.css";
import React, { useState } from "react";
import DateTimePicker from "react-date-picker";
import "./Calendar.css";
import { getGlobalState, setGlobalState } from "../../State/TakeSurveyGlobalState";

interface CalendarProps {
    index: number
}

export function Calendar({index}: CalendarProps) {
    const [value, setValue] = useState<Date | undefined>();

    const onChange = (date: Date) => {
        setValue(date);
        const answers = getGlobalState("answers");
        answers[index] = date.toString();
        setGlobalState("answers", answers);
    }

    return (
        <div data-testid={'calendar'} className={"calendar-section"}>
            <DateTimePicker isOpen={false} onChange={onChange} value={value} className={"calendar"} />
        </div>
    );
}

