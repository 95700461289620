import "../../App.css";
import "../RadioQuestion/RadioQuestionCSS.css";
import { MultiAnswerBox } from "../MultiAnswerBox/MultiAnswerBox";

interface RankQuestionProps {
    answers?: string[],
    updateAnswerArray?: (newAnswerArray: string[])=>void
}

export function RankQuestionBox(props: RankQuestionProps) {
    return (<MultiAnswerBox questionType="rank" answers={props.answers} updateAnswerArray={props.updateAnswerArray}/>);
}