import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Stylesheets/AlertModal.css";

interface confirmModalProps {
    show: boolean,
    message: string,
    confirm: () => void,
    cancel: () => void
}

export function ConfirmationModal({show, message, confirm, cancel}: confirmModalProps) {
    return (
        <Modal
            onHide={cancel}
            show={show}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header
            data-testid = {"confirmation-modal"}
             className={"header"}>
                <p className={"message"}>
                    {message}
                </p>
            </Modal.Header>
            <Modal.Footer className={"footer"}>
                <button className={"modal-btn"} onClick={confirm} data-testid={"confirm-modal-button"}>Yes</button>
                <button className={"modal-btn"} onClick={cancel} data-testid={"cancel-modal-button"}>Cancel</button>
            </Modal.Footer>
        </Modal>
    );
}
