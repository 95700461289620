import "../../App.css";
import "./ShortAnswer.css";

export function ShortAnswer() {
    return (
        <>
            <div className={"answer-section-textarea"}
                 data-testid={"short-answer"}>
                <input className={"short-answer-box"}
                       disabled={true}/>
            </div>
        </>
    );
}
