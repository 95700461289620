import { useLocation } from "react-router-dom";

export function Login() {
  const urlObjectParams = new URLSearchParams(useLocation().search);
  let authorizeURL =
    "https://corp.sts.ford.com/adfs/oauth2/authorize?" +
    "response_type=token&" +
    "client_id=urn:dealersurvey:clientid:web_dealersurvey:prod&" +
    "resource=urn:dealersurvey:resource:web_dealersurvey:prod&" +
    "redirect_uri=" +
    window.location.origin +
    `/oauth-callback`;

  if (urlObjectParams.has("surveyid")) {
    authorizeURL += "?surveyid=" + urlObjectParams.get("surveyid");
  }

  window.location.assign(authorizeURL);
  return null;
}
