import axios from "axios";
import {setGlobalState} from "../State/GlobalState";
import {getEncodedAccessToken, invalidateLogin} from "../auth/OAuthParser";


export function getHttpClient() {
    axios.interceptors.request.use((config) => {
        const token = getEncodedAccessToken();

        if (config.headers === undefined) config.headers = {};

        config.headers.Authorization = "Bearer " + token;

        return config;
    });

    function isDate(value: string) {
        const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/
        return isoDateRegex.test(value);
    }

    function convertDates(objectToConvert: any) {
        if (objectToConvert != null) {
            let keys = Object.keys(objectToConvert);
            if (keys.length !== 0) {
                for (let key of keys) {
                    let value = objectToConvert[key]

                    if (typeof value != "string") {
                        objectToConvert[key] = convertDates(objectToConvert[key])
                    } else {
                        if (isDate(value)) {
                            objectToConvert[key] = new Date(value);
                        }
                    }

                }
            }
        }
        return objectToConvert
    }

    axios.interceptors.response.use(
        (response) => {
            console.log(response)
            response.data = convertDates(response.data);
            return response

        },
        (error) => {
            if (error.response.status === 401) {
                invalidateLogin();
            } else {
                setGlobalState("errorModalVisible", true);
            }
            return Promise.reject(error)
        }
    );
    return axios;
}
