import { getHttpClient } from "./API";
import {SurveyInterface} from "../interfaces/SurveyInterface";
import {AxiosResponse} from "axios";
import {SubmissionInterface} from "../interfaces/SubmissionInterface";

export function getSurveyById(id: string): Promise<AxiosResponse<SurveyInterface>> {
    let httpClient = getHttpClient();
    return httpClient.get("/api/get-survey/" + id);
}

export async function getAllSurveys(): Promise<AxiosResponse<SurveyInterface[]>> {
    let httpClient = getHttpClient()
    return await httpClient.get("/api/survey")
}

export async function makePublished(id: string) {
    let httpClient = getHttpClient();
    return await httpClient.put("/api/make-published/" + id);
}

export async function makeUnpublished(id: string) {
    let httpClient = getHttpClient();
    return await httpClient.put("/api/make-unpublished/" + id);
}

export function exportSurveyResponses(id: string): Promise<any> {
    let httpClient = getHttpClient();
    return httpClient({url:"/api/download-submissions/" + id, method: 'GET', responseType: 'blob'});
}

export function exportUserSurveyResponses(id: string): Promise<any> {
    let httpClient = getHttpClient();
    return httpClient({url:"/api/download-survey/" + id, method: 'GET', responseType: 'blob'});
}

export function deleteSubmissions(id: string): Promise<any> {
    let httpClient = getHttpClient();
    return httpClient.delete("/api/delete-submissions/"+id)
}

export function saveSurvey(data: SurveyInterface): Promise<any> {
    let httpClient = getHttpClient();
    return httpClient.post("/api/save-survey", data)
}

export function deleteSurvey(id: String): Promise<AxiosResponse> {
    let httpClient = getHttpClient();
    return httpClient.delete("/api/survey/" + id);
}

export async function getRole() {
    let httpClient = getHttpClient();
    const axiosResponse = await httpClient.get("/api/user/role");
    return axiosResponse.data.role;
}

export async function getSubmissionsByIdAndCdsid(surveyId: String): Promise<AxiosResponse<SubmissionInterface[]>> {
    let httpClient = getHttpClient();
    return await httpClient.get("/api/submissions/" + surveyId + "/byCdsid");
}
