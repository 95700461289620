import "../Stylesheets/TakeSurvey.css";
import React, {useEffect, useState} from "react";
import {SurveyHeader} from "../generalComponents/SurveyHeader";
import {SurveyFooter} from "../generalComponents/SurveyFooter";
import {TakeQuestion} from "../takeSurveyComponents/takeQuestion/TakeQuestion";
import {getSubmissionsByIdAndCdsid, getSurveyById} from "../serviceFunctions/SurveyService";
import {useParams} from "react-router-dom";
import {QuestionInterface} from "../interfaces/QuestionInterface";
import {useGlobalState} from "../State/TakeSurveyGlobalState";
import axios from "axios";
import {AlertModal} from "../generalComponents/AlertModal";
import {SurveyInterface} from "../interfaces/SurveyInterface";
import {isSurveyInactive} from "../utils/IsSurveyInactive";

export function TakeSurvey() {
    const [questionsArr, setQuestionsArr] = useState([] as QuestionInterface[]);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [survey, setSurvey] = useState({} as SurveyInterface);
    const [errorMessage, setErrorMessage] = useState("");
    

    const [surveyIsTaken, setSurveyIsTaken] = useState(false);
    const [surveyId, setSurveyId] = useGlobalState("surveyId");

    const [answers, setAnswers] = useGlobalState("answers");

    const params = useParams();

    function SurveyError(props: { hidden: boolean }) {
        if(!errorMessage) return null;
        return <div className={"padding"} hidden={props.hidden}>
            <section className={"survey-error-section"}>
                <p className={"survey-error"}>
                    {errorMessage}
                </p>
            </section>
        </div>;
    }

    const handleCloseModal = () => {
        setShowErrorModal(false);
    };

    function setUpSurvey(survey: SurveyInterface) {
        setSurvey(survey)
        setQuestionsArr(survey.questionsArray);
        let answersArray: string[] = [];
        survey.questionsArray.forEach(() => answersArray.push(""));
        setAnswers(answersArray);
    }

    useEffect(() => {
        if (params.id !== undefined ) {
            let surveyResponse = getSurveyById(params.id);
           
            let submissionResponse = getSubmissionsByIdAndCdsid(params.id);
           
            Promise.resolve(submissionResponse).then((value) => {
                if (value?.data.length > 0 && errorMessage ==="") {
                    setErrorMessage("You have already responded to this survey.");
                    setSurveyIsTaken(true);
                } else {
                    // Only fetch and set the survey details if the survey has not been taken by the user
                    Promise.resolve(surveyResponse).then((value) => {
                        if (value?.data !== undefined) {
                            setUpSurvey(value.data);
                            if (isSurveyInactive(survey) && (errorMessage ==="")) {
                                setErrorMessage("This survey is closed.");
                                return;
                            }
                        }
                    });
                }
            });
    
            setSurveyId(params.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id]);

    function saveSubmission() {
        axios.post("/api/save-submission", {
            surveyId: surveyId,
            name: sessionStorage.getItem("firstName") + " " + sessionStorage.getItem("lastName"),
            answers: answers
        })
            .then(() => {
                sessionStorage.setItem("savedSuccess", "true");
                setErrorMessage("Thank you for submitting this survey.")
                setSurveyIsTaken(true)
            })
            .catch(() => {
                sessionStorage.setItem("savedSuccess", "false");
                setErrorMessage("Failed to save submission");
                setShowErrorModal(true);
            });
    }

    const validateRequiredAnswers = () => {
        for (let questionCount = 0; questionCount < questionsArr.length; ++questionCount) {
            if (questionsArr[questionCount].required && answers[questionCount] === "") {
                setErrorMessage("Please fill in all required fields (*) before submitting the survey.");
                sessionStorage.setItem("savedSuccess", "false");
                setShowErrorModal(true);
                return;
            }
        }
        saveSubmission();
    };

    return (
        <>
            <AlertModal show={showErrorModal}
                        onHide={handleCloseModal}
                        message={errorMessage}
                        data-testid={"submit-survey-modal"}/>
            <SurveyHeader/>
            <main>
                <SurveyError hidden={errorMessage === "" || (!isSurveyInactive(survey) && !surveyIsTaken)}/>
                <div className={"survey-section"} hidden={isSurveyInactive(survey) || surveyIsTaken}>
                    <section className={"take-information-section"}>
                        <label data-testid="take-survey-title"
                               id={"take-survey-title"}>
                            {survey.title}
                        </label>
                        <label data-testid="take-survey-description"
                               id={"take-survey-description"}>
                            {survey.description}
                        </label>
                    </section>
                    <section className="survey-container"
                             data-testid={"survey-container"}>
                        {questionsArr.map((q, index) => (
                            <TakeQuestion index={index}
                                          q={q}
                                          key={q.questionText + index}/>
                        ))}
                    </section>
                    <section className={"button-container-column"}>
                        <button className={"bottom-buttons"}
                                id={"submit-survey"}
                                data-testid={"submit-survey"}
                                hidden={!survey.published}
                                onClick={validateRequiredAnswers}>
                            Submit Survey
                        </button>
                    </section>
                </div>
            </main>
            <SurveyFooter/>
        </>
    )
}
