import "../../App.css";
import "./BlockTextCSS.css";
import MDEditor from "@uiw/react-md-editor";
import React, {useState} from "react";
import {QuestionInterface} from "../../interfaces/QuestionInterface";

// @ts-ignore
export function BlockText(props: {
    index: number,
    question: QuestionInterface,
    updateQuestion: (question: QuestionInterface, index: number) => void
}) {
    const [markdownText, setMarkdownText] = useState(props.question.questionText);

    const updateAnswers = () => {
        const newQuestion = {...props.question, questionText: markdownText}
        props.updateQuestion(newQuestion, props.index)

    }

    return (
        <>
            <div className={"answer-section-textarea"}
                 data-testid={"block-text"}
                 data-color-mode="light">
                <MDEditor
                    textareaProps={{title:"block-text-editor-textarea",
                        "aria-label":"block-text-textarea"
                    }}
                    className={"markdown"}
                    value={markdownText}
                    data-testid={"block-text-editor"}
                    onChange={(e) => setMarkdownText(e?.toString() || "")}
                    onBlur={updateAnswers}
                />
            </div>
        </>
    );
}