import update from "immutability-helper";
import { useCallback, useEffect, useState } from "react";
import "./TakeRankCSS.css";
import { AnswerOption } from "./AnswerOption";
import { getGlobalState, setGlobalState } from "../../State/TakeSurveyGlobalState";

export interface Item {
    id: number
    text: string
}

export interface AnswerProps {
    answers: string[],
    index: number
}


export const AnswerContainer = (props: AnswerProps) => {
    const buildOptionData = () => {
        const optionsByIndex: Item[] = []

        for (let i = 0; i < props.answers.length; i += 1) {
            const item = {id: i + 1, text: props.answers[i]}
            optionsByIndex[i] = item
        }

        return optionsByIndex;
    }

    const [option, setOption] = useState([
        ...buildOptionData()
    ])

    const moveOption = useCallback((dragIndex: number, hoverIndex: number) => {
        setOption((prevCards: Item[]) =>
            update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex] as Item],
                ],
            }),
        )
    }, [])

    const renderOption = useCallback(
        (option: { id: number; text: string }, index: number) => {
            return (
                <AnswerOption
                    key={option.id}
                    index={index}
                    id={option.id}
                    text={option.text}
                    moveOption={moveOption}
                />
            )
        },
        [moveOption],
    )

    useEffect(() => {
        let value: string = "";
        for (let i = 0; i < option.length; i++) {
            value += option[i].text + ", ";
        }
        const answers = getGlobalState("answers");
        answers[props.index] = value.slice(0, -2);
        setGlobalState("answers", answers);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [option]);

    return (
        <>
            <div className={'container'}>
                {option.map((card, i) =>
                    renderOption(card, i))}
            </div>
        </>
    )
}