import type { Identifier, XYCoord } from "dnd-core";
import type { FC } from "react";
import React, { useRef } from "react";
import { ItemTypes } from "./ItemTypes";
import { useDrag, useDrop } from "react-dnd";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextareaAutosize from "react-textarea-autosize";
import "./MultiAnswerCSS.css";

export interface OptionProps {
  id: any;
  text: string;
  index: number;
  moveOption: (dragIndex: number, hoverIndex: number) => void;
  updateAnswer: (answer: string, index: number) => void;
  removeAnswer: (index: number) => void;
  disabledRemove: boolean;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const MultiAnswerOption: FC<OptionProps> = ({
                                                     id,
                                                     text,
                                                     index,
                                                     moveOption,
                                                     updateAnswer,
                                                     removeAnswer,
                                                     disabledRemove
                                                   }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [, drop] = useDrop<DragItem,
    void,
    { handlerId: Identifier | null }>({
    accept: ItemTypes.OPTION,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the item's height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveOption(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });

  const [, drag] = useDrag({
    type: ItemTypes.OPTION,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    })
  });

  drag(drop(ref));
  return (
    <>
      <div ref={ref} className="answer-entry">
        <FontAwesomeIcon className={"square-icon"} icon={faBars} />
        <TextareaAutosize defaultValue={text}
                          placeholder={`Option-${1 + index}`}
                          data-testid={"answer-option-" + index}
                          className="answer-input"
                          onBlur={(e) => {
                            updateAnswer(e.target.value, index);
                          }}>
        </TextareaAutosize>
        <button onClick={() => removeAnswer(index)}
                className={"remove-answer-button"}
                title={"Remove Answer " + (index + 1)}
                disabled={disabledRemove}
                data-testid={"remove-answer-button-" + index}>
          <FontAwesomeIcon className={"delete-x-icon"} icon={faXmark} />
        </button>
      </div>
    </>
  );
};