import { SurveyHeader } from "../generalComponents/SurveyHeader";
import { SurveyFooter } from "../generalComponents/SurveyFooter";
import { setGlobalState } from "../State/GlobalState";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  exportSurveyResponses,
  exportUserSurveyResponses,
  getSurveyById,
} from "../serviceFunctions/SurveyService";
import { isAdmin } from "../auth/AdminParser";
import { SurveyInterface } from "../interfaces/SurveyInterface";

export function SurveyDownloadPage() {
  const params = useParams();
  const [downloadAuthorized, setDownloadAuthorized] = useState(true);
  const [survey, setSurvey] = useState({} as SurveyInterface);

  useEffect(() => {
    if (params.id !== undefined) {
      let surveyResponse = getSurveyById(params.id);
      Promise.resolve(surveyResponse).then((value) => {
          if (value?.data !== undefined) {
            setSurvey(value.data);
          }
      });
    }
  }, [params.id])

  function SurveyError(props: { hidden: boolean }) {
    return (
      <div className={"padding"} hidden={props.hidden}>
        <section className={"survey-error-section"}>
          <p className={"survey-error"}>You do not have access to this page</p>
        </section>
      </div>
    );
  }

  function createDownloadLink(response: { data: BlobPart }) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("data-testid", "download-link");
    link.setAttribute("download", survey.title + ".csv");
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  }

  if (survey.id !== undefined) {
    if (isAdmin()) {
      Promise.resolve(exportSurveyResponses(survey.id))
        .then((response) => {
          if(survey.title !== undefined){
            createDownloadLink(response);
          }
          
        })
        .catch((e) => {
          setDownloadAuthorized(false);
          setGlobalState("errorModalVisible", false);
        });
    } else {
      Promise.resolve(exportUserSurveyResponses(survey.id))
        .then((response) => {
          if(survey.title !== undefined){
            createDownloadLink(response);
          }
        })
        .catch((e) => {
          setDownloadAuthorized(false);
          setGlobalState("errorModalVisible", false);
        });
    }
  }

  function renderProperComponent() {
    if (!downloadAuthorized) {
      return (
        <>
          <SurveyHeader />
          <SurveyError hidden={false} />
          <SurveyFooter />
        </>
      );
    } else {
      return <></>;
    }
  }
  return renderProperComponent();
}
