import { createGlobalState } from "react-hooks-global-state";

const initialState = {
  errorModalVisible: false
}

export const { useGlobalState, setGlobalState, getGlobalState } =
  createGlobalState(initialState);

export const resetGlobalState = () => {
  setGlobalState("errorModalVisible", false)
}