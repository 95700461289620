export function isAuthenticated() {
    return sessionStorage.getItem("firstName") !== null;
}

export function setEncodedAccessToken(accessToken: string) {
    sessionStorage.setItem("encodedAccessToken", accessToken);
}

export function getEncodedAccessToken() {
    return sessionStorage.getItem("encodedAccessToken");
}

export function invalidateLogin() {
    sessionStorage.clear();
    window.location.reload();
}

function parseAndStoreAccessTokenVariables(urlSearchParams: URLSearchParams) {
    const accessToken = urlSearchParams.get("access_token") as string;
    setEncodedAccessToken(accessToken);
    const accessTokenJson = JSON.parse(atob(accessToken.split(".")[1]));
    sessionStorage.setItem("cdsid", accessTokenJson["sub"]);
    sessionStorage.setItem("tokenExp", accessTokenJson["exp"]);
    sessionStorage.setItem("firstName", accessTokenJson["givenname"]);
    sessionStorage.setItem("lastName", accessTokenJson["surname"]);
}

export function parseOAuthCallbackUrl(url: string) {
    const urlParameters = url.split("#")[1];
    const urlSearchParams = new URLSearchParams(urlParameters);

    parseAndStoreAccessTokenVariables(urlSearchParams);
}
