import "../../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

export function TrueFalse() {
    const answers = ['True', 'False']
    return (
        <>
            <div className={"answer-section"}
                 data-testid={'true-false'}>
                <ul className={"multi-bullets"}>
                    {answers.map((answer: string) => {
                        return(
                            <li className="radio-answer" key={answer}>
                                <FontAwesomeIcon className={'square-icon'} icon={faBars}/>
                                <input disabled value={answer}
                                       className="answer-input"/>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </>
    )
}
