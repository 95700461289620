import "../Stylesheets/CreateSurvey.css";
import React, {useEffect, useState} from "react";
import {QuestionInterface} from "../interfaces/QuestionInterface";
import {SurveyHeader} from "../generalComponents/SurveyHeader";
import {SurveyFooter} from "../generalComponents/SurveyFooter";
import {Question} from "../createSurveyComponents/Question/Question";
import {AlertModal} from "../generalComponents/AlertModal";
import {useNavigate, useParams} from "react-router-dom";
import {getSurveyById, saveSurvey} from "../serviceFunctions/SurveyService";
import {SurveyInterface} from "../interfaces/SurveyInterface";
import "react-datepicker/dist/react-datepicker.css";
import {CreateSurveyDatePicker} from "../createSurveyComponents/CreateSurveyDatePicker/CreateSurveyDatePicker";


export function CreateSurvey() {
  const baseQuestion: QuestionInterface = { questionText: "", type: "blank", answers: [], required: false };
  const navigate = useNavigate();
  const [questionsArr, setQuestionsArr] = useState([baseQuestion]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [message] = useState("Successfully saved");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [datesAreValid, setDatesAreValid] = useState(true);
  const [missingInfo, setMissingInfo] = useState(false);
  const params = useParams();
  const multiAnswerTypes = ["radio", "multi-select", "rank"];
  const [exportUsers, setExportUsers] = useState('');
  const addQuestion = () => {
    setQuestionsArr([...questionsArr, { questionText: "", type: "blank", answers: [], required: false }]);
  };

  const questionsHaveBlankFields = () => {
    const isQuestionTitleOrTypeEmpty = (question: QuestionInterface) => {
      return question.questionText === "" || question.type === "blank";
    };

    const multiAnswerQuestionHasBlankFields = (question: QuestionInterface) => {
      for (let answer of question.answers) {
        if (answer === "") {
          return true;
        }
      }
      return false;
    };

    return questionsArr.reduce((hasEmptyFields, question) => {
      if (isQuestionTitleOrTypeEmpty(question)) {
        return true;
      }
      if (multiAnswerTypes.includes(question.type) && !hasEmptyFields) {
        return multiAnswerQuestionHasBlankFields(question);
      }
      return hasEmptyFields;
    }, false);
  };

  const handleSaveSurvey = () => {
    if (isSurveyMissingInformation()) {
      setMissingInfo(true);
      return;
    } else {
      let finalData: SurveyInterface;
      let data: SurveyInterface;
      let exportCdsids = exportUsers.split(',');
      data = {
        title: title,
        description: description,
        questionsArray: questionsArr,
        published: false,
        startDate: startDate,
        endDate: endDate,
        exportUsers: exportCdsids
      };
      finalData = data;
      if (params?.id !== undefined) {
        finalData = { ...data, id: params.id };
      }

      saveSurvey(finalData)
        .then(() => {
          setShowAlertModal(true);
        })
        .catch(() => {
        });
    }

  };

  const handleExportUsersChange =(e: React.ChangeEvent<HTMLInputElement>) => {
    setExportUsers(e.target.value);
  }

  const handleKeyPress= (e: React.KeyboardEvent<HTMLInputElement>) => {
    const character = e.key;

    if(!/^[a-zA-Z0-9,]$/.test(character)){
      e.preventDefault();
    }
  }

  useEffect(() => {
    if (params !== undefined && params.id !== undefined) {
      let response = getSurveyById(params.id);
      Promise.resolve(response).then((value) => {
        setTitle(value.data.title);
        setQuestionsArr(value.data.questionsArray);
        setDescription(value.data.description);
        setStartDate(value.data.startDate);
        setEndDate(value.data.endDate);
        setExportUsers(value.data.exportUsers.join(","))
      }).catch((err) => alert(err));
    }
  }, [params]);

  const removeQuestion = (id: number) => {
    if (questionsArr.length > 1) {
      questionsArr.splice(id, 1);
      setQuestionsArr([...questionsArr]);
    }
  };

  const updateQuestion = (question: QuestionInterface, index: number) => {
    setQuestionsArr((currentQuestionArr) => {
      currentQuestionArr[index] = question;
      return [...currentQuestionArr];
    });
  };

  const hideModal = () => {
    if (!isSurveyMissingInformation()) {
      navigate("/manage-surveys");
    }
    setShowAlertModal(false);
  };

  function isSurveyMissingInformation() {
    return title === "" || questionsHaveBlankFields() || startDate === null;
  }

  function getSurveyTitle() {
    let titleLabel = "New Survey"
    if(params.id !== undefined){
      titleLabel = title
    }

    return <h1 data-testid="survey-header-title">
      {titleLabel}
    </h1>
  }

  function getTitleLabel() {
    let titleLabel = "Title"
    if(missingInfo && title === ""){
      titleLabel = "Title is missing"
    }

    return <label htmlFor="text-box-title"
                  data-testid="survey-title"
                  id={"survey-title"}
                  className={"required-asterisk"}>
      {titleLabel}
    </label>
  }

  return (
    <>
      <AlertModal show={showAlertModal} onHide={hideModal}
                  message={message}
                  data-testid={"create-survey-modal"} />
      <SurveyHeader />
      <main>
        <form>
          <div className={"survey-section"}>
            <section className={"information-section"}>
              {getSurveyTitle()}
              {getTitleLabel()}
              <input type="text"
                     className={missingInfo && title === "" ? "text-box missing-input" : "text-box"}
                     data-testid= {"text-box-title"}
                     id={"text-box-title"}
                     defaultValue={title}
                     onChange={(e) => {
                       setTitle(e.target.value);
                     }}
                     required>
              </input>
              <label htmlFor={"text-box-description"}
                     data-testid="survey-description"
                     id={"survey-description"}>
                Description
              </label>
              <textarea className={"text-box"}
                        data-testid={"text-box-description"}
                        id={"text-box-description"}
                        placeholder={"What's this survey about?"}
                        defaultValue={description}
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}/>
              <label htmlFor={"text-box-export-users"}
                     data-testid="survey-export-users"
                     id={"survey-export-users"}>
                Export Users
              </label>
              <input type="text"
                    className={missingInfo && title === "" ? "text-box missing-input" : "text-box"}
                     data-testid= {"text-box-export-users"}
                     id={"text-box-export-users"}
                     onChange={handleExportUsersChange}
                     onKeyPress={handleKeyPress}
                     defaultValue={exportUsers}
                     >
              </input>
            </section>
            <CreateSurveyDatePicker
              initialStartDate={startDate}
              initialEndDate={endDate}
              setSurveyEndDate={setEndDate}
              setSurveyStartDate={setStartDate}
              setSurveyDatesAreValid={setDatesAreValid}
              datesAreValid={datesAreValid}
              missingInfo={missingInfo}
            />
            <section className="survey-container"
                     data-testid={"survey-container"}>
              <h2 hidden={true}>Survey Questions</h2>
              {questionsArr.map((q, index: number) => (
                  <Question index={index}
                            questionInterface={q}
                            removeQuestion={removeQuestion}
                            updateQuestion={updateQuestion}
                            key={q.type + q.questionText + index}
                            missingInfo={missingInfo}
                  />
                )
              )}
            </section>
            <div>
              <section className={"button-container-column"}>
                <button className={"bottom-buttons"}
                        type="button"
                        id={"add-question"}
                        onClick={addQuestion}
                        data-testid={"add-question-button"}>
                  Add Question
                </button>
                <button className={"bottom-buttons"}
                        type="button"
                        id={"save-exit"}
                        onClick={handleSaveSurvey}
                        data-testid={"save-exit"}
                >
                  Save and Exit
                </button>
              </section>
            </div>
          </div>
        </form>
      </main>
      <SurveyFooter />
    </>
  );
}
