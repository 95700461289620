import React, { useEffect } from "react";
import DatePicker from "react-datepicker";

interface CreateSurveyDatePickerProps {
  initialStartDate: Date | null;
  initialEndDate: Date | null;
  datesAreValid: boolean;
  setSurveyStartDate: (date: Date | null) => void;
  setSurveyEndDate: (date: Date | null) => void;
  setSurveyDatesAreValid: (b: boolean) => void;
  missingInfo: boolean;
}

export function CreateSurveyDatePicker(props: CreateSurveyDatePickerProps) {

  useEffect(() => {
    if (props.initialStartDate != null && props.initialEndDate != null) {
      const areDatesValid = props.initialEndDate >= props.initialStartDate;
      props.setSurveyDatesAreValid(areDatesValid);
    }
  }, [props]);

  function getStartDateLabel() {
    let startDateLabel = "Start Date";
    if (props.missingInfo && props.initialStartDate === null) {
      startDateLabel = "Start Date is missing";
    }

    return <label htmlFor="survey-start-date"
                  className={"required-asterisk"}>
      {startDateLabel}
    </label>;
  }

  return (<>
    <section className={"survey-activity-section"}>
      {getStartDateLabel()}
      <div className={props.missingInfo && props.initialStartDate === null ?
          "text-box missing-input"
          :
          "text-box"}
           data-testid="date-picker-survey-start">
        <DatePicker
          selected={props.initialStartDate}
          id={"survey-start-date"}
          onChange={(date: Date | null) => {
            props.setSurveyStartDate(date);
          }}
          isClearable={true}
          strictParsing={true}
          className={props.missingInfo && props.initialStartDate === null ?
            "survey-date-picker missing-input"
            :
            "survey-date-picker"}/>
      </div>
      <label htmlFor="survey-end-date">
        End Date
      </label>
      <div className={"text-box"} data-testid="date-picker-survey-end">
        <DatePicker
          selected={props.initialEndDate}
          id={"survey-end-date"}
          onChange={(date: Date | null) => {
            props.setSurveyEndDate(date);
          }}
          isClearable={true}
          strictParsing={true}
          className={"survey-date-picker"}/>
        <span
          data-testid={"incorrect date error"}
          hidden={props.datesAreValid}
        >
          End date must be a valid date after start date
      </span>
      </div>
    </section>
  </>);
}
