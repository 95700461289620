import { SurveyInterface } from "../interfaces/SurveyInterface";
import { Card, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Stylesheets/SurveyCard.css";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons/faEllipsisV";
import { faFolder } from "@fortawesome/free-solid-svg-icons/faFolder";
import { faFolderBlank } from "@fortawesome/free-regular-svg-icons/faFolderBlank";
import { faLink } from "@fortawesome/free-solid-svg-icons/faLink";
import { faPencil } from "@fortawesome/free-solid-svg-icons/faPencil";
import React, { useState } from "react";
import {
  deleteSubmissions, deleteSurvey,
  exportSurveyResponses,
  getSurveyById,
  makePublished,
  makeUnpublished
} from "../serviceFunctions/SurveyService";
import { useNavigate } from "react-router-dom";
import { ConfirmationModal } from "./ConfirmationModal";
import {isSurveyInactive} from "../utils/IsSurveyInactive";

export function SurveyCard({
                             initialSurvey,
                             updateSurveyList
                           }: { initialSurvey: SurveyInterface, updateSurveyList: () => void }) {
  const [survey, setSurvey] = useState(initialSurvey);
  const [copiedLink, setCopiedLink] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isDeletingSurvey, setIsDeletingSurvey] = useState(false);
  const [exportLinkCopied, setExportLinkCopied] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();

  function updateSurvey() {
    if (survey.id !== undefined) {
      let response = getSurveyById(survey.id);

      Promise.resolve(response).then(value => {
        if (value.data !== undefined) {
          setSurvey(value.data);
        }
      });
    }
  }

  const renderTooltip = (props: any) => (
    <Tooltip data-testid={"tooltip"} id="button-tooltip" {...props}>
      {copiedLink ? "Copied!" : "Copy Survey Link"}
    </Tooltip>
  );

  function copySurveyLink() {
    let port = "";
    if (window.location.port) {
      port = ":" + window.location.port;
    }
    const link = window.location.protocol + "//" + window.location.hostname + port + "/take-survey/" + survey.id;
    Promise.resolve(navigator.clipboard.writeText(link)).catch(() => alert("Failed to copy link."));
  }

  function copyExportLink(){
    let port = "";
    if (window.location.port) {
      port = ":" + window.location.port;
    }
    const link = window.location.protocol + "//" + window.location.hostname + port + "/export/" + survey.id;
    Promise.resolve(navigator.clipboard.writeText(link)).catch(() => alert("Failed to copy link."));
  }

  function getCopySurveyLinkButton() {
    return <OverlayTrigger
      placement="left"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
      defaultShow={false}
      onHide={undefined}
      popperConfig={undefined}
      onToggle={() => {
        setTimeout(() => {
          setCopiedLink(false);
        }, 150);
      }}>
      <button className={"survey-link"}
              aria-label={"copy-survey-link"}
              data-testid={"survey-link-" + survey.id}
              onClick={() => {
                copySurveyLink();
                setCopiedLink(true);
              }}>
        <FontAwesomeIcon icon={faLink} />
      </button>
    </OverlayTrigger>;
  }

  function exportResponses() {
    if (survey.id !== undefined) {
      Promise.resolve(exportSurveyResponses(survey.id)).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("data-testid", "download-link");
        link.setAttribute("download", survey.title + ".csv");
        document.body.appendChild(link);
        link.click();
      })
        .catch(() => alert("Failed to get responses"));
    }
  }

  function clearSubmissions() {
    if (survey.id !== undefined) {
      Promise.resolve(deleteSubmissions(survey.id))
        .then(() => {
          updateSurvey();
        })
        .catch(() => {
          alert("Failed to Delete Data");
        });
    }
  }

  function getSurveyMenuDropdown() {
    return <Dropdown className={"survey-dropdown"} autoClose="outside" show={showMenu} onToggle={() => {setShowMenu(!showMenu)}}>
      <Dropdown.Toggle aria-label={survey.id + "-menu"} className={"survey-menu"}
                       data-testid={"survey-menu-" + survey.id}>
        <FontAwesomeIcon icon={faEllipsisV} />
      </Dropdown.Toggle>

      <Dropdown.Menu className={"dropdown-menu"}>
        <Dropdown.Item className={"menu-item"}
                       onClick={() => {
                         if (survey.id !== undefined) {
                           if (survey.published) {
                             makeUnpublished(survey.id).then(() => {
                               updateSurvey()
                               updateSurveyList();
                             });
                           } else {
                             makePublished(survey.id).then(() => {
                               updateSurvey()
                               updateSurveyList();
                             });
                           }
                         }
                         setShowMenu(false)
                       }}
        >
          {survey.published ? "Unpublish" : "Publish"}
        </Dropdown.Item>

        <Dropdown.Item
          className={"menu-item"}
          onClick={() => {
            exportResponses();
            setShowMenu(false)
          }}
        >
          Export Data
        </Dropdown.Item>

        <OverlayTrigger
          placement="left"
          overlay={exportLinkCopied? <Tooltip data-testid={"tooltip"} id="button-tooltip">Copied!</Tooltip> : <></>}>
        <Dropdown.Item
          className={"menu-item"}
          onClick={() => {
            copyExportLink();
            setExportLinkCopied(true)
            setTimeout(() => {
              setExportLinkCopied(false)
              setShowMenu(false)
            }, 1000);
          }}
        >
          Copy Export Link
        </Dropdown.Item>
        </OverlayTrigger>

        <Dropdown.Item
          className={"clear-data-button menu-item"}
          onClick={() => {
            setShowConfirmModal(true);
            setShowMenu(false)
          }}>
          Clear Data
        </Dropdown.Item>

        {
          !survey.published ?
            <Dropdown.Item
              className={"delete-survey-button"}
              onClick={() => {
                setIsDeletingSurvey(true);
                setShowConfirmModal(true);
                setShowMenu(false)
              }}>
              Delete Survey
            </Dropdown.Item>
            : ""
        }

      </Dropdown.Menu>
    </Dropdown>;
  }

  function getActiveStatus() {
    if (isSurveyInactive(survey)) {
      return <FontAwesomeIcon className={"survey-status-circle"}
                              data-testid={"survey-status-inactive"}
                              icon={faCircle}
      />;
    } else {
      return <FontAwesomeIcon className={"survey-status-circle survey-circle-active"}
                              data-testid={"survey-status-active"}
                              icon={faCircle}
      />;
    }
  }

  function getSurveyStatus() {
    return <div className={"survey-status"}>
      <FontAwesomeIcon className={"folder"}
                       data-testid={survey.published ? "published" : "unpublished"}
                       icon={survey.published ? faFolder : faFolderBlank}
      />

      {getActiveStatus()}
    </div>;
  }

  function getEditSurveyButton() {
    return <OverlayTrigger
      placement="left"
      delay={{ show: 250, hide: 400 }}
      overlay={(props: any) => (
        <Tooltip id="edit-button-tooltip" {...props}>
          Edit Survey
        </Tooltip>
      )}
      defaultShow={false}
      onHide={undefined}
      popperConfig={undefined}
      onToggle={undefined}
    >
      <button className={"survey-link"}
              aria-label={"edit-survey-link"}
              data-testid={"survey-edit-" + survey.id}
              onClick={() => {
                navigate(`/create-survey/${survey.id}`);
              }}>
        <FontAwesomeIcon icon={faPencil} />
      </button>
    </OverlayTrigger>;
  }

  function getSurveyMenuAndStatus() {
    return <div className={"survey-menu-and-status"}>
      {survey.published ? getCopySurveyLinkButton() : getEditSurveyButton()}
      {getSurveyMenuDropdown()}
      {getSurveyStatus()}
    </div>;
  }

  function getSurveyStartAndEndDate() {
    let startDateText = "Start: ";
    if (survey.startDate !== null) {
      startDateText += survey.startDate.toLocaleDateString("en-us", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit"
      });
    } else {
      startDateText += "--/--/--";
    }

    let endDateText = " End: ";
    if (survey.endDate !== null) {
      endDateText += survey.endDate.toLocaleDateString("en-us", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit"
      });
    } else {
      endDateText += "--/--/--";
    }

    return startDateText + endDateText;
  }

  function getSurveyDetails() {
    return <div>
      <div className={"survey-title"}>
        {survey.title}
      </div>
      <div>
        {survey.description.length >= 100 ? survey.description.substring(0, 100) + "..." : survey.description}
      </div>
      <div>
        {"Author: " + survey.author}
      </div>
      <div>
        {getSurveyStartAndEndDate()}
      </div>
      <div>
        {"Submissions: " + survey.numSubmissions}
      </div>
    </div>;
  }

  function handleCloseConfirmModal() {
    setShowConfirmModal(false);
  }

  async function deleteSurveyAndUpdateList() {
    if (survey.id !== undefined) {
      Promise.resolve(deleteSurvey(survey.id))
        .then(() => {
          updateSurveyList();
        })
        .catch(() => {
          alert("Failed to Delete Survey");
        });
    }
  }

  function getConfirmationModal() {
    let message = isDeletingSurvey
      ? "Are you sure you want to delete '" + survey.title + "'?"
      : "Are you sure you want to clear all submissions for '" + survey.title + "'?";

    return <ConfirmationModal
      show={showConfirmModal}
      cancel={handleCloseConfirmModal}
      confirm={() => {
        isDeletingSurvey ? deleteSurveyAndUpdateList() : clearSubmissions();
        handleCloseConfirmModal();
      }}
      message={message}
    />;
  }

  return (
    <Card>
      {getConfirmationModal()}
      <div className={"survey-card-grid"}>
        {getSurveyDetails()}
        {getSurveyMenuAndStatus()}
      </div>
    </Card>
  );
}